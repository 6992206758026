<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <inventory-form
      type="edit"
      :read-only="isView"
      :item="item"
      :loading="isLoading"
      @onclose="closeForm"
      @onsave="updateItem"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    InventoryForm: () => import("../utils/InventoryForm.vue"),
  },
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    isView: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      tab: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },
  // async mounted() {
  //   if (this.authUser.isAdmin) {
  //     await this.$store.dispatch("meta/getClients", this.mdMake.make_type);
  //   }
  // },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    async updateItem(row) {
      this.isLoading = true;
      await this.$store
        .dispatch("inventory/update", row)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.isLoading = false;
          this.toggleEdit();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.isLoading = false;
        });
    },
    closeForm() {
      // this.$store.commit("csmsLocations/SET_LOCATION_IMG_LIST", []);
      this.toggleEdit();
    },
  },
};
</script>
